import React from 'react';
import Container from '../components/container';
import SEO from '../components/seo';
import { Col, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Content from '../components/Layout/Content';
import Title from '../Ui/Typography/Title';
import Span from '../Ui/Typography/Span';
import Text from '../Ui/Typography/Text';
import styled from 'styled-components';
import Section from '../components/Layout/Section';
import { media } from '../Ui/text-sizes';
import Team from '../assets/tpl_team.jpg';
import linkedin from '../assets/linkedin2.png';
import ShowMore from '../assets/arrow-down.png';
import ShowLess from '../assets/arrow-up.png';
import ShowMoreText from 'react-show-more-text';

const SecondPage = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
        query Founders {
          allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/founders/"}}, sort: {fields: [frontmatter___order], order: ASC}) {
                edges {
                    node {
                        frontmatter {
                            name
                            description
                            position
                            image
                            order
                            linkedin
                        }
                    }
                }
            }
        }
`);
  const mainData = allMarkdownRemark.edges;
  return (
    <Container defKey='1'>
      <Section space></Section>
      <SEO title='Our team' />
      <Content>
        <Col xs={24} sm={12} md={20} lg={16}>
          <Title level={2} color='darkGray' topSpace={19} bottomSpace={34} mobileBottomSpace={20} letterMd>
            Product <Span type='gradient2'> <span class="bold">focused</span> </Span> founders with years of experience and empathy.
          </Title>
        </Col>
        <Col xs={24} sm={22} lg={16}  style = {{marginTop:'-10px'}}>
          <Text level='t4' style = {{color:'#6C6C6C'}}>
            We are experienced founders and operators that have raised tens of millions in funding and successfully exited multiple companies. Our team of 80+ engineers, designers, and product managers work with exceptional founders to build and launch incredible products.

        </Text>
        </Col>
      </Content>
      <Section spaceTop={1} mobileTopSpace={0} space>
        {mainData.map((item, index) => <R justify='space-between' gutter={30} key={index} style = {{marginTop:'50px'}} >
          <Col xs={24} sm={7} md={8} lg={8} xl={7}>
            <I src={item.node.frontmatter.image} />
          </Col>
          <Col xs={24} sm={17} md={16}  lg={16} xl={17}>
            <Title level={4} bottomSpace={0} color='lightBlack' mobileBottomSpace={7} style={{paddingLeft:'5px'}}>
            <span class="bold">  {item.node.frontmatter.name}  </span>
            <a style={{padding:'5px'}} href={item.node.frontmatter.linkedin} target='__blank'>
              <SocialIcon src={linkedin} />
            </a>
            </Title>
              
            <Title level={5} color='gray' bottomSpace={5}  style={{paddingLeft:'5px', marginTop:'-15px'}}>
             <Text level='t4' style={{color:'#646464', fontSize:'18px'}} > {item.node.frontmatter.position} </Text>
            </Title>


            <Title level={5} color='darkGrayNew' bottomSpace={5} topSpace={0} style={{paddingLeft:'5px', marginTop:'18px'}}>
            <Text level='t4' colorType='darkGrayNew'>
              <ShowMoreText 
                /* Default options */
                lines={7}
           
                keepNewLines={true}
                more={<div style={{ marginTop: 19, display: 'flex', alignItems: 'center' }}><Text level='t5' colorType={'orange'} link>
                  Show More
                </Text>
                  <img src={ShowMore} style={{ marginLeft: 7 }} width={18} />
                </div>}
                less={
                  <div style={{ marginTop: 19, display: 'flex', alignItems: 'center', paddingLeft:'5px' }}>
                    <Text level='t5' colorType={'orange'} link>
                      Show Less
                  </Text>
                    <img src={ShowLess} style={{ marginLeft: 7 }} width={18} />

                  </div>
                }
                className='content-css'
                anchorClass='my-anchor-css-class'
                expanded={false}
              >
                {item.node.frontmatter.description}
              </ShowMoreText>
            </Text>
            </Title>



            {/* <a href={item.node.frontmatter.linkedin} target='__blank'>
              <SocialIcon src={linkedin} />
            </a> */}
          </Col>
        
{/*           
          <Col xs={24} sm={9} lg={10}>
          
          </Col> */}
    
        </R>
        )}
      </Section>
      <Section id={'careers'} gradientBg mobileBottomSpace={40} style={{paddingLeft:'7px'}}>
        {/* <Text level='t4' upperSeperators colorType='orange'>
          Our team
        </Text> */}
        <Col xs={24} sm={13} lg={10}>
          <Title level={2} color='darkGray' topSpace={39} mobileBottomSpace={20} letterMd bottomSpace={34} style={{paddingLeft:'0px', marginTop:'30px'}}>
            We are<Span type='gradient2'> <span class="bold">dedicated</span> </Span> problem solvers
             </Title>
        </Col>
        <Col xs={24} sm={20} lg={15} style={{paddingLeft:'0px', marginTop:'-10px'}} >
          <Text level='t4' style={{color:'#6C6C6C'}}>
            Tidepool Labs gives entrepreneurs what they need most - top tier engineers, experienced advisors, and funding.
          </Text>
        </Col>
        {/* <Row  style={{marginTop: 40}}>
        <Text level='t4' upperSeperators colorType='orange'>
          We are hiring
        </Text>
        </Row> */}
        {/* <Row style={{marginTop: 10}}>
        <Col>
           <Text level='t4'>
            Software Team Lead
          </Text>
          <Text level='t4'>
             {" "}(San Francisco / hybrid)
          </Text>
            <Link to='/software-team-lead/'>

          <Text level='t3' colorType={'orange'}>
            {"  "}
             Read More
          </Text>
            </Link>

          </Col>
        </Row> */}
        <Col xs={24}>
          <ImageTeam src={Team} width='100%' />
        </Col>
      </Section>
    </Container>
  )
};

const SocialIcon = styled.img`
  width: 25px;
  
  ${media.down('md')} {
    margin-bottom: 20px;
    margin-top: 17px;
  }
  
  ${media.down('md')} {
    width: 33px;
  }
`;

const ImageTeam = styled.img`
  width:100%;
  margin-top: 55px;
  
  ${media.down('md')} {
    margin-top: 40px;
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
  } 
`;

const I = styled.img`
  width:338px;
  
  ${media.down('md')} {
    width:100%;
    margin-bottom: 28px;
  }
  @media (max-width: 999px) {
    margin-top: 20px;
  }
`;

const R = styled(Row)`
  margin-top: 63px;
  
  ${media.down('md')} {
    margin-top: 42px;
  }
`;

export default SecondPage;

